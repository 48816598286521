var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "position-absolute" },
    [
      _c("b-modal", {
        ref: "personalization-editor-modal",
        attrs: {
          id: "personalization-editor-modal",
          centered: "",
          size: "lg",
          "footer-class": "flex-nowrap",
          "no-close-on-backdrop": ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t("settings.page.context.title")))
                ]),
                _c(
                  "b-dropdown",
                  {
                    staticClass: "context-menu",
                    attrs: {
                      "toggle-class": "dot-button",
                      "no-caret": "",
                      "no-flip": "",
                      right: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "button-content",
                        fn: function() {
                          return [
                            _c("b-icon", {
                              attrs: {
                                icon: "three-dots-vertical",
                                "aria-hidden": "true",
                                scale: "1"
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _c("b-dropdown-item", { on: { click: _vm.copySettings } }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("dropdowns.copySettings")) + " "
                      )
                    ]),
                    _vm.settingsCopied
                      ? _c(
                          "b-dropdown-item",
                          { on: { click: _vm.pasteSettings } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("dropdowns.pasteSettings")) +
                                " "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _vm.personalization
                  ? _c("page-settings-context", {
                      staticClass: "mt-5",
                      attrs: {
                        "context-data": _vm.context,
                        "personalization-values": _vm.personalizationValues,
                        "all-actions-override": _vm.allActions
                      },
                      on: {
                        "context-create": _vm.createContext,
                        "context-update": _vm.updateContext,
                        "context-element-update": _vm.updateContextElement,
                        "context-copy": _vm.copyContext,
                        "remove-element": _vm.removeElement,
                        "remove-context": _vm.removeContext
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function() {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 border-right-light-grey",
                    attrs: { block: "" },
                    on: { click: _vm.cancelModal }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: { variant: "primary", block: "" },
                    on: { click: _vm.saveContext }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.ok")) + " ")]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }